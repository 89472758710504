<template>
  <main>
    <package-header title="Edit Application" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col">

          <div class="mb-3 border-bottom pb-3">
            <!-- Back to applications link -->
            <router-link class="mr-2" to="/pages/applications">
              <svg class="icon icon-arrow_cta_back mr-sm-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
              <span class="d-none d-sm-inline-block">Return to Applications</span>
              <span class="d-sm-none">Back</span>
            </router-link>

            <!-- Change instrument dropdown -->
            <a href="javascript:void(0)" class="btn btn-md btn-secondary dropdown-toggle d-sm-inline-block" id="instrument" data-toggle="dropdown" aria-controls="instrument-contents" aria-expanded="false">
              Go To Application
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="javascript:void(0)">01 - Transfer Caso <span class="badge badge-success">E-signed</span></a>
              <a class="dropdown-item font-weight-bold" href="javascript:void(0)">02 - A1 release</a>
              <a class="dropdown-item" href="javascript:void(0)">03 - Mortgage Caso</a>
              <a class="dropdown-item" href="javascript:void(0)">04 - A2 release</a>
              <a class="dropdown-item" href="javascript:void(0)">05 - Application with longer name</a>
            </div>
          </div>

          <!-- Package title and actions  -->
          <div class="d-md-flex align-items-start justify-content-between">
            <div>
              <h2 class="page-title">A1 release</h2>

              <div class="d-flex align-items-center">
                <small>
                  <span class="text-muted text-small">Application Type: </span>
                  <strong>Application Type</strong>
                </small>
                <button class="btn btn-secondary btn-sm ml-2">Change</button>
              </div>

              <ul class="list-inline mb-1">
                <li class="list-inline-item">
                  <small><span class="text-muted text-small">Status:</span> <strong>Drafted</strong></small>
                </li>
                <li class="list-inline-item">
                  <small><span class="text-muted">Modified:</span> <strong>Sep 22, 2018 at 5:33 PM</strong></small>
                </li>
              </ul>
            </div>

            <!-- Drawer toggle and delete package -->
            <div class="d-flex justify-content-between">
              <button class="btn btn-md btn-secondary d-md-none mb-2" type="button" data-toggle="collapse" data-target="#application-nav" aria-expanded="false" aria-controls="application-nav">
                <svg class="icon icon-more"><use xlink:href="/icons/symbol-defs.svg#icon-more"></use></svg>
                <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
                Navigate Application
              </button>
              <button type="button" class="btn btn-md btn-danger mb-2">
                <svg class="icon icon-delete d-sm-none"><use xlink:href="/icons/symbol-defs.svg#icon-delete"></use></svg>
                <span class="d-none d-sm-block">Delete Application</span>
              </button>
            </div>
          </div>

          <!-- Main package content -->
          <div class="row mt-md-3">

            <!-- Step wizard -->
            <div class="col-md-3 mb-2 mb-md-4">
              <div class="collapse collapse-md" id="application-nav">
                <div class="step-wizard">
                  <div class="step completed">
                    <router-link class="step-link" to="/pages/edit-application/applicant">Applicant</router-link>
                  </div>
                  <div class="step completed">
                    <router-link class="step-link" to="/pages/edit-application/pid-legal-description">Description of Land</router-link>
                  </div>
                  <div class="step active">
                    <router-link class="step-link" to="/pages/mortgage-borrower">Borrower (Mortgagor)</router-link>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Lender (Mortgagee)</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Payment Provision</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Interest Mortgaged and Floating/Security Details</a>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/mortgage-terms">Mortgage Terms</router-link>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/execution">Execution</router-link>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Supporting Evidence or Declaration</a>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/validation-summary">Validation Summary</router-link>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/electronic-instrument">Electronic Instrument</router-link>
                  </div>
                </div>
              </div>
            </div>

            <!-- Mortgage form entry -->
            <div class="col-md-9">
              <div class="mb-3">

                <h2>Borrower (Mortgagor)</h2>
                <p class="lead mb-2">Add the full name of the borrower (mortgagor) as it appears on title.</p>

                <a class="collapse-toggle" href="#mortgageHelpCollapse" data-toggle="collapse" aria-expanded="false" aria-controls="mortgageHelpCollapse">
                  <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                  <svg class="icon icon-close-circle"><use xlink:href="/icons/symbol-defs.svg#icon-close-circle"></use></svg>
                  What do I do for changed or amalgamated names?
                </a>
                <div class="collapse mt-1" id="mortgageHelpCollapse">
                  <div class="card bg-light">
                    <div class="card-body">
                      If a corporation has changed its name or amalgamated, enter new name formerly known as old name.
                    </div>
                  </div>
                </div>
              </div>

              <!-- New borrower Group -->
              <div id="borrowers">
                <template v-for="(group, index) in groups">
                  <mortage-borrower-group
                    :group="group"
                    :groupIndex="index"
                    :key="index"
                    @onAddBorrower="addBorrowerToGroup"
                    @onDeleteBorrower="removeBorrowerFromGroup"
                    @onSaveGroup="saveGroup"
                    @onCancelGroup="deleteGroup"
                    @onSaveBorrower="saveBorrower"
                    @onCancelBorrower="cancelBorrower"
                    @onCollapse="collapseBorrower"
                    @onBack="viewAllGroups"
                  ></mortage-borrower-group>
                </template>
              </div>

              <!-- Borrowers saved  -->
              <div class="mb-4 p-2 p-md-4 bg-medium" v-if="notEditingGroup">
                <template v-if="groupsAdded">
                  <div class="card mb-4">
                    <table class="table mb-0">
                      <thead>
                        <tr>
                          <th>Borrower Groups</th>
                          <th>Joint Tenants</th>
                          <th>Undivided interest</th>
                          <th class="text-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(group, index) in groups" :key="index" v-show="group.saved">
                          <td>
                            <ol class="list-unstyled mb-0">
                              <li v-for="(name, index) in group.borrowers" :key="index">
                                {First and last name {{ index + 1 }}}
                              </li>
                            </ol>
                            <p><strong>Capacity:</strong><br>{ capacity description }</p>
                          </td>
                          <td>
                            Yes
                          </td>
                          <td>
                            <div class="form-inline">
                              <div class="form-group">
                                <input class="form-control form-control-sm mr-sm-1 d-inline-block" style="width:2.5rem" name="interestNum" id="interestNum" type="text">
                              </div>
                              <div class="form-group">
                                <label for="">/</label>
                                <input class="form-control form-control-sm mx-sm-1  d-inline-block" style="width:2.5rem" name="interestNumOf" id="interestNumOf" type="text">
                              </div>
                            </div>
                          </td>
                          <td class="text-right">
                            <button class="btn btn-sm btn-link pr-0" @click="editGroup(index)">View/Edit</button><br>
                            <button class="btn btn-sm btn-link text-danger mt-1 pr-0" @click="deleteGroup(index)">Delete</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </template>

                <!-- Add New Borrower group -->
                <h3 v-if="groups.length > 0">
                  Add Fractional Interest Borrower:
                  <a href="#" data-placement="top" title="This is an example of a tooltip" v-tooltip>
                    <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                  </a>
                </h3>

                <div class="d-flex flex-column align-items-start">
                  <button class="btn btn-md btn-tertiary mb-2" @click="addGroup('individual')">+ Add Individual to a new group</button>
                  <button class="btn btn-md btn-tertiary" @click="addGroup('corporation')">+ Add Corporation to a new group</button>
                </div>
              </div>

              <!-- Save and continue -->
              <div class="d-flex flex-column-reverse flex-sm-row justify-content-sm-between d-print-none">
                <div class="mt-2 mt-sm-0">
                  <button class="btn btn-secondary">Back</button>
                </div>
                <div class="d-flex justify-content-between">
                  <button class="btn btn-secondary mr-1">Save Progress</button>
                  <button class="btn btn-primary">Continue <svg class="icon icon-arrow_cta ml-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use></svg></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Applications"></help-panel>
  </main>
</template>

<script>

import MortageBorrowerGroup from './MortageBorrowerGroup.vue'
import PackageHeader from '../../../../components/PackageHeader.vue'
import PackageNavigation from '../../../../components/PackageNavigation.vue'
import HelpPanel from '../../../../components/HelpPanel.vue'

export default {

  components: {
    'mortage-borrower-group': MortageBorrowerGroup,
    'package-header': PackageHeader,
    'package-navigation': PackageNavigation,
    'help-panel': HelpPanel
  },

  data () {
    return {
      groups: [],
      groupscollapseToggle: [],
    }
  },

  computed: {
    groupsAdded() {
      return this.groups.some( ({ saved }) => saved === true );
    },

    notEditingGroup() {
      if(this.groups.length == 0) {
        return true
      } else if(this.groups.some( ({ editing }) => editing === true )) {
        return false
      } else {
        return true
      }
    }
  },

  methods: {
    addGroup(type) {
      this.groups.push({saved: false, editing: true, borrowers: [{type: type, collapsed: false, capacity: false}]})
      document.getElementById('borrowers').scrollIntoView()
    },

    addBorrowerToGroup(index, type) {
      this.groups[index].borrowers.forEach( borrower => borrower.collapsed = true)
      this.groups[index].borrowers.push({type: type, collapsed: false, capacity: false})
    },

    removeBorrowerFromGroup(group, index) {
      this.groups[group].borrowers.splice(index, 1)
    },

    collapseBorrower(group, index) {
      this.groups[group].borrowers[index].collapsed = !this.groups[group].borrowers[index].collapsed
    },

    saveBorrower(group, index) {
      this.groups[group].borrowers[index].collapsed = true
      document.getElementById('borrowers').scrollIntoView()
    },

    cancelBorrower(group, index) {
      this.groups[group].borrowers.splice(index, 1)
    },

    editGroup(group) {
      this.groups[group].editing = true;
    },

    saveGroup(index) {
      this.groups[index].saved = true;
      this.groups[index].editing = false;
    },

    deleteGroup(index) {
      this.groups.splice(index, 1);
    },

    viewAllGroups(group) {
      this.groups[group].editing = false;
    }
  }
}
</script>
