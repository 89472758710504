<template>

  <!-- Borrowers form  -->
  <div class="mb-4 bg-medium" v-if="group.editing">
    <div class="bg-medium-dark p-2">
      <h3 class="mb-0">
        <a href="#" v-if="group.editing && group.saved" @click.prevent="$emit('onBack', groupIndex)">
          <svg class="icon icon-arrow_cta_back mr-sm-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
        </a>
        Borrower Group {{ groupIndex + 1 }}</h3>
    </div>

    <div class="p2 p-md-4 ">
      <p class="lead">Add and enter information for all borrowers for this group.</p>

      <!-- Borrower Form -->

      <div class="custom-control custom-checkbox mr-sm-2">
        <input type="checkbox" class="custom-control-input" id="capacityPrompt" v-model="capacity">
        <label class="custom-control-label" for="capacityPrompt">I want to enter a capacity for this group</label>
      </div>
      <div class="form-group mt-2" v-if="capacity">
        <label for="Capacity">Capacity</label>
        <input class="form-control col-6" type="text" name="Capacity" id="Capacity">
      </div>

      <!-- Borrower collapse -->
      <div class="mt-4">
        <div v-for="(borrower, index) in group.borrowers" :key="index">
          <div class="d-flex p-1" v-bind:class="{ 'bg-white border-bottom': borrower.collapsed }">
            <button class="btn collapse-btn text-truncate" data-toggle="collapse" :data-target="'#collapse' + index" aria-expanded="true" aria-controls="'collapse' + index" @click.prevent="$emit('onCollapse', groupIndex, index)">
              <svg class="icon icon-arrow_right collapse-icon"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"></use></svg>
              <strong>Borrower {{ index + 1 }}</strong>
              <span v-if="borrower.collapsed"> - { Borrower Name }</span>
            </button>
            <button class="btn btn-link text-danger pr-0" v-if="borrower.collapsed" @click="$emit('onDeleteBorrower', groupIndex, index)">
              Delete
            </button>
          </div>

          <div :id="'collapse' + index" class="collapse ml-3" :class="{ show: !borrower.collapsed}" aria-labelledby="borrower1Heading">
            <div class="row mb-2">
              <div class="col-sm-10 col-lg-9">

                <!-- Individual Borrower form -->
                <template v-if="borrower.type === 'individual'">
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="lastName">Last Name</label>
                      <input class="form-control" type="text" name="lastName" id="lastName">
                    </div>
                    <div class="form-group col-md-6">
                      <label for="givenName">Given Name(s)</label>
                      <input class="form-control" type="text" name="givenName" id="givenName" >
                    </div>
                  </div>
                  <div class="form-group" v-if="!capacity">
                    <label for="occupation">
                      Occupation (optional)
                      <a href="#" data-toggle="popover" data-placement="top" data-title="Help Text" data-content="This is an example of some help text" v-popover>
                        <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                      </a>
                    </label>
                    <input class="form-control" type="text" name="occupation" id="occupation">
                  </div>
                  <div class="form-group">
                    <label class="d-flex justify-content-between" for="address1">
                      Address
                      <button class="btn btn-sm btn-secondary" v-if="index > 0">Copy previous borrower's address</button>
                    </label>
                    <input class="form-control" type="text" name="address1" id="address1">
                    <input class="form-control mt-1" type="text" name="address2" id="address2">
                    <small class="form-text text-muted">Second line of address</small>
                  </div>
                  <div class="form-group">
                    <label for="country">Country</label>
                    <select class="form-control" name="country" id="country">
                      <option value="AB">Canada</option>
                      <option value="BC">United Kingdom</option>
                      <option value="MB">United States</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="city">City</label>
                    <input class="form-control" type="text" name="city" id="city">
                  </div>
                  <div class="form-row">
                    <div class="form-group col-lg-8">
                      <label for="province">Province</label>
                      <select class="form-control" name="province" id="province">
                        <option value="AB">Alberta</option>
                        <option value="BC">British Columbia</option>
                        <option value="MB">Manitoba</option>
                        <option value="NB">New Brunswick</option>
                        <option value="NL">Newfoundland and Labrador</option>
                        <option value="NT">Northwest Territory</option>
                        <option value="NS">Nova Scotia</option>
                        <option value="NU">Nunavut</option>
                        <option value="ON">Ontario</option>
                        <option value="PE">Prince Edward Island</option>
                        <option value="QC">Quebec</option>
                        <option value="SK">Saskatchewan</option>
                        <option value="YT">Yukon Territory</option>
                      </select>
                    </div>
                    <div class="form-group col-lg-4">
                      <label for="postal-code">Postal Code</label>
                      <input class="form-control" name="postal-code" id="postal-code" autocomplete="shipping postal-code">
                    </div>
                    <div class="mt-2 mb-4">
                      <button class="btn btn-md btn-tertiary" type="submit" @click="$emit('onSaveBorrower', groupIndex, index)">Done</button>
                      <button class="btn btn-md btn-link" @click="$emit('onCancelBorrower', groupIndex, index)">Cancel</button>
                    </div>
                  </div>
                </template>

                <!-- Corporation borrower form -->
                <template v-if="borrower.type === 'corporation'">
                  <div class="form-group mt-2">
                    <label for="corporationName">Corporation Name</label>
                    <input class="form-control" type="text" name="corporationName" id="corporationName">
                  </div>
                  <div class="form-group">
                    <label for="corporationNumber">Incorporation Number (optional)</label>
                    <input class="form-control" type="text" name="corporationNumber" id="corporationNumber">
                  </div>
                  <div class="form-group">
                    <label for="capacity">
                      Capacity
                      <a href="#" data-toggle="popover" data-placement="top" data-title="Help Text" data-content="This is an example of some help text" v-popover>
                        <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                      </a>
                    </label>
                    <input class="form-control" type="text" name="capacity" id="capacity">
                  </div>
                  <div class="form-group">
                    <label for="address1">Address</label>
                    <input class="form-control" type="text" name="address1" id="address1">
                    <input class="form-control mt-1" type="text" name="address2" id="address2">
                    <small class="form-text text-muted">Second line of address</small>
                  </div>
                  <div class="form-group">
                    <label for="country">Country</label>
                    <select class="form-control" name="country" id="country">
                      <option value="AB">Canada</option>
                      <option value="BC">United Kingdom</option>
                      <option value="MB">United States</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="city">City</label>
                    <input class="form-control" type="text" name="city" id="city">
                  </div>
                  <div class="form-row">
                    <div class="form-group col-lg-8">
                      <label for="province">Province/State</label>
                      <select class="form-control" name="province" id="province">
                        <option value="AB">Alberta</option>
                        <option value="BC">British Columbia</option>
                        <option value="MB">Manitoba</option>
                        <option value="NB">New Brunswick</option>
                        <option value="NL">Newfoundland and Labrador</option>
                        <option value="NT">Northwest Territory</option>
                        <option value="NS">Nova Scotia</option>
                        <option value="NU">Nunavut</option>
                        <option value="ON">Ontario</option>
                        <option value="PE">Prince Edward Island</option>
                        <option value="QC">Quebec</option>
                        <option value="SK">Saskatchewan</option>
                        <option value="YT">Yukon Territory</option>
                      </select>
                    </div>
                    <div class="form-group col-lg-4">
                      <label for="postal-code">Postal Code</label>
                      <input class="form-control" name="postal-code" id="postal-code" autocomplete="shipping postal-code">
                    </div>
                  </div>
                  <div class="mt-2 mb-4">
                    <button class="btn btn-md btn-tertiary" type="submit" @click="$emit('onSaveBorrower', groupIndex, index)">Done</button>
                    <button class="btn btn-md btn-link" @click="$emit('onCancelBorrower', groupIndex, index)">Cancel</button>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

        <!-- Add additional borrower to group -->
      <template v-if="editingBorrower">
        <h3 class="mt-4 mb-2">Add additional borrower:
          <a href="#" data-toggle="popover" data-placement="top" data-title="Help Text" data-content="This is an example of some help text" v-popover>
            <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
          </a>
        </h3>
        <div class="d-flex flex-column align-items-start">
          <button class="btn btn-link pl-0" @click="$emit('onAddBorrower', groupIndex, 'individual')">
            + Add individual to this group
          </button>
          <button class="btn btn-link pl-0" @click="$emit('onAddBorrower', groupIndex, 'corporation')">
            + Add corporation to this group
          </button>
        </div>

        <!-- Joint tenants -->
        <div v-if="group.borrowers.length > 1">
          <div class="pt-4 mb-2">
            Are the borrowers joint tenants?
            <a href="#" data-toggle="popover" data-placement="top" data-title="Help Text" data-content="This is an example of some help text" v-popover>
              <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
            </a>
          </div>

          <div class="form-group">
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="jointTenants" name="jointTenants" class="custom-control-input">
              <label class="custom-control-label" for="jointTenants">Yes</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="jointTenants2" name="jointTenants" class="custom-control-input">
              <label class="custom-control-label" for="jointTenants2">No</label>
            </div>
          </div>
        </div>

        <!-- Save section -->
        <div class="mt-3">
          <button class="btn btn-md btn-tertiary" type="submit" @click="$emit('onSaveGroup', groupIndex)">Save Group</button>
          <button class="btn btn-md btn-link" @click="$emit('onCancelGroup', groupIndex)">Cancel</button>
        </div>
      </template>
    </div>


  </div>

</template>

<script>

export default {

  data () {
    return {
      capacity: false,
      sameAddress: true,
      id: Math.random().toString(36).substr(2, 9)
    }
  },

  computed: {
    editingBorrower() {
      return this.group.borrowers.every( ({ collapsed }) => collapsed === true );
    }
  },

  props: {
    group: Object,
    groupIndex: Number,
    collapsed: Boolean,
    type: String
  }
}
</script>
